
import './layout.scss'

export default function CommonHeader(){
    return (
        <div className='headerModule p_fixed flex align_center justify_between bd_b bd_a_c_6_1'>
            <img src='./images/logo/logo_new.png' style={{height:"42px"}} />

            <div className='flex align_center gap_48 s-hide'>
                <a className='a_c_6 fs_16 hover_c_1' href='https://docs.paddlefi.com/' target='_blank'>DOCS</a>
                <a className='a_c_6 fs_16 hover_c_1' href='https://twitter.com/PaddleFi' target='_blank'>TWITTER</a>
                {/* <a className='a_c_6 fs_16 hover_c_1' href="https://community.paddlefi.com/" target="_blank">COMMUNITY</a> */}
                <a className='a_c_6 fs_16 hover_c_1 text_upper' href='https://t.me/PaddleFi' target='_blank'>Telegram</a>
                
                <a className="flex align_center b_g_1 br_12 px_24 py_8 cursor " href='https://dapp.paddlefi.com' target='_blank'>
                    <span className='fs_16 fw_600'>LAUNCH APP</span>
                    <span className="icon p-icon-more is_24"></span>
                </a>
            </div>

            <div className='flex align_center gap_48 l-hide m-hide'>
                <a className="flex align_center b_g_1 br_12 px_12 py_8 cursor l-hide m-hide" href='https://dapp.paddlefi.com' target='_blank'>
                    <span className='fs_14 fw_600'>LAUNCH APP</span>
                    <span className="icon p-icon-more is_20"></span>
                </a>
            </div>
        </div>
    )
}